// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-electorates-tsx": () => import("./../../../src/pages/electorates.tsx" /* webpackChunkName: "component---src-pages-electorates-tsx" */),
  "component---src-pages-enquiries-tsx": () => import("./../../../src/pages/enquiries.tsx" /* webpackChunkName: "component---src-pages-enquiries-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parliamentarians-tsx": () => import("./../../../src/pages/parliamentarians.tsx" /* webpackChunkName: "component---src-pages-parliamentarians-tsx" */),
  "component---src-pages-parties-tsx": () => import("./../../../src/pages/parties.tsx" /* webpackChunkName: "component---src-pages-parties-tsx" */),
  "component---src-pages-project-overview-tsx": () => import("./../../../src/pages/project-overview.tsx" /* webpackChunkName: "component---src-pages-project-overview-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-search-advanced-tsx": () => import("./../../../src/pages/search/advanced.tsx" /* webpackChunkName: "component---src-pages-search-advanced-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-electorate-tsx": () => import("./../../../src/templates/electorate.tsx" /* webpackChunkName: "component---src-templates-electorate-tsx" */),
  "component---src-templates-parliamentarian-tsx": () => import("./../../../src/templates/parliamentarian.tsx" /* webpackChunkName: "component---src-templates-parliamentarian-tsx" */),
  "component---src-templates-party-tsx": () => import("./../../../src/templates/party.tsx" /* webpackChunkName: "component---src-templates-party-tsx" */)
}

